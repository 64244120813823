import {PatientAnswers} from "../record/patient-answers";
import {useUtils} from "../../hooks/utils-hook";
import {PrinterIcon} from "@heroicons/react/outline";

export function QuestionResponse({record = {}}) {
    let {ConvertDateToString, DownloadPdf} = useUtils()
    return <>
        <div className='w-20 m-auto'>
        <span className="isolate inline-flex rounded-md shadow-sm m-auto mb-6 mt-6 align-middle w-20">
          <button
              type="button"
              className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              onClick={() => DownloadPdf('printArea', `${record?.record_patient?.practice[0]?.name}-${record?.record_form?.name}-${ConvertDateToString(record?.created)}`)}
          >
            <PrinterIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true"/>
            Print
          </button>
        </span>
        </div>
        <div className='shadow-2xl w-full  border-2 border-r-2 mb-8 p-3'>
            <div id={'printArea'}>
                <img className='text-center mb-3 mt-8 m-auto' alt={'Logo'}
                     src={record?.record_patient?.practice[0]?.logo}/>
                <h3 className='text-center font-bold text-xs'>{record?.record_patient?.practice[0]?.name}</h3>
                <h3 className='text-center text-xs'>{record?.record_patient?.practice[0]?.address}</h3>
                <h3 className='text-center text-xs'>{record?.record_patient?.practice[0]?.email}</h3>
                <h3 className='text-center text-xs'>{record?.record_patient?.practice[0]?.contact}</h3>
                <h3 className='text-center mt-3 text-xs'>{record?.record_form?.name} - Patient Record</h3>
                <h3 className='text-center mb-5 text-xs'>Completed - {ConvertDateToString(record?.created)}</h3>
                <hr className='mt-2 mb-2 w-2/4 text-center m-auto'/>
                <PatientAnswers answers={record?.answers}/>
            </div>
        </div>
    </>
}
