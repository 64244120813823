import {CheckCircleIcon, XIcon} from "@heroicons/react/outline";

export function PatientAnswers({answers = []}) {
    function can_display(answer) {
        return !(answer === "No" && false);
    }

    const display_answer = (answer) => {
        if (answer === "Yes") {
            return <CheckCircleIcon className={'h-4 -ml-1'} style={{color: "#27ae60"}}/>;
        }
        if (answer === "No") {
            return <XIcon className={'h-4 -ml-1'} style={{color: "#e74c3c"}}/>;
        }
        if (answer === "") return "N/A";
        return answer;
    };


    return <>
        {
            answers.map((ans) => {
                return (
                    <div className="p-8 pl-12">
                        {
                            ans.title !== "" ? (
                                <div className="w-full mt-1">
                                    <h5 className="">
                                        <strong>{ans.title}</strong>
                                    </h5>
                                </div>
                            ) : null
                        }
                        {
                            ans.questions.map((q, i) => {
                                return can_display(q.answer) ? (
                                    <div className='grid gap-6 grid-cols-4'>
                                        <div key={i} className="mb-4 col-span-3">
                                            <h5 className="text-xs">{`${i + 1}. ${q.question}`}</h5>
                                        </div>
                                        <div className="mb-2">
                                            <h5 className="font-bold text-xs">
                                                {display_answer(q.answer)}
                                            </h5>
                                        </div>
                                    </div>
                                ) : null;
                            })
                        }
                    </div>
                );
            })
        }
    </>
}
