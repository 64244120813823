import {Link, useHistory} from "react-router-dom";
import {useContext, useState} from "react";
import axios from "axios";
import * as Sentry from '@sentry/react'
import {CogIcon} from "@heroicons/react/outline";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {useAuthentication} from "../hooks/AuthenticationHook";
import LOGO from 'assets/Icon.svg'
import {API_ROUTES} from "../routes/api-routes";
import {ErrorMsg, SuccessMsg} from "../components/notification";

export default function Login() {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [requested, setRequested] = useState(false);
    const {setAuthentication} = useAuthentication();
    const {setUser} = useContext(AuthenticationContext)

    let history = useHistory()

    function request_otp() {
        setLoading(true)
        if (details?.username === '' || details?.cellphone === '')
            return
        axios.get(API_ROUTES.AUTHENTICATION.USER_OTP, {
            params: {
                "id_number": details?.username,
                "cellphone": details?.cellphone
            }
        }).then(() => {
            setRequested(true)
            SuccessMsg('Please sign in with your OTP')
        }).catch(error => {
            ErrorMsg('Credentials not valid, please get a referral')
            Sentry.captureException(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    function login_user() {
        setLoading(true)
        if (details?.username === '' || details?.cellphone === '')
            return
        axios.post(API_ROUTES.AUTHENTICATION.LOGIN, {
            "username": details?.username,
            "password": details?.otp
        }).then(json => {
            setUser(json.data)
            setAuthentication(json.data)
            history.push(`/`);
            window.location.reload()
            SuccessMsg('Welcome to Clavkoo')
        }).catch(error => {
            ErrorMsg('Credentials not valid, please try again')
            Sentry.captureException(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    return <>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-32 w-auto"
                    src={LOGO}
                    alt="WiEnergi"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-600">Sign In</h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 shadow-2xl border-2 border-gray-200">
                    <form className="space-y-6" method="POST" onSubmit={(event) => {
                        event.preventDefault()
                        !requested ? request_otp() : login_user()
                    }}>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                ID Number
                            </label>
                            <div className="mt-1">
                                <input
                                    name="text"
                                    type="text"
                                    disabled={requested}
                                    required
                                    onChange={(event) => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`username`]: event.target.value,
                                        }))
                                    }}
                                    value={details?.username}
                                    className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                { requested ? 'OTP': 'Cellphone'}
                            </label>
                            <div className="mt-1">
                                <input
                                    name="text"
                                    type="text"
                                    required
                                    className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                    onChange={(event) => {
                                        setDetails((prevState) => ({
                                            ...prevState,
                                            [`${requested ? 'otp' : 'cellphone'}`]: event.target.value,
                                        }))
                                    }}
                                    value={requested ? details?.otp ?? '' : details?.cellphone}
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-emerald-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {
                                    loading ? <CogIcon
                                            className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                        <>{requested ? 'Sign In' : 'Request OTP'}</>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
