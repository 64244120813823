import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import PageView from "components/page-view";
import GoogleAnalytics from "components/google-analytics";
import {AuthenticationProvider} from "./contexts/AuthenticationContext";
import {PORTAL_MENU} from "./routes/portal";
import PrivateRoute from "./routes/private-route";
import {LoaderContextProvider} from "./contexts/loader-context";
import {CrumbsContextProvider} from "./contexts/crumbs-context";

export default function App() {

    return <>
        <AuthenticationProvider>
            <LoaderContextProvider>
                <Router>
                    <PageView/>
                    <GoogleAnalytics/>
                    <CrumbsContextProvider>
                        <Switch>
                            {
                                PORTAL_MENU.map((route, index) => {
                                    return route.private ?
                                        <PrivateRoute key={index} exact={route.exact} path={route.link}
                                                      component={route.page}/>
                                        : <Route key={index} exact={route.exact} path={route.link}
                                                 component={route.page}/>
                                })
                            }
                        </Switch>
                    </CrumbsContextProvider>
                </Router>
            </LoaderContextProvider>
        </AuthenticationProvider>
    </>
}
