import {useContext, useEffect} from "react";
import {LoaderContext} from "../contexts/loader-context";
import {FormsGrid} from "../components/forms/forms-grid";
import {useAuthentication} from "../hooks/AuthenticationHook";
import Breadcrumbs from "../components/breadcrumbs";
import {CrumbsContext} from "../contexts/crumbs-context";

export default function Forms() {
    let {setLoading} = useContext(LoaderContext)
    let {currentUser} = useAuthentication()
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        setCrumbs([{'name': 'Outstanding Forms', 'link': '/forms'}])
    }, [])

    return <div className='max-h-full h-screen'>
        <Breadcrumbs/>
        <FormsGrid id_number={currentUser()?.id_number}/>
    </div>
}
