import {useHistory, useLocation} from "react-router-dom";
import React, {useContext, useEffect, useMemo, useState} from "react";
import axios from "axios";
import * as Sentry from '@sentry/react'
import {CogIcon} from "@heroicons/react/outline";
import {AuthenticationContext} from "../contexts/AuthenticationContext";
import {useAuthentication} from "../hooks/AuthenticationHook";
import LOGO from 'assets/Icon.svg'
import {API_ROUTES as CMS_URLS, API_ROUTES} from "../routes/api-routes";
import {ErrorMsg, SuccessMsg} from "../components/notification";
import {Switch} from "@headlessui/react";


export default function Register() {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [requested, setRequested] = useState(false)
    const [practices, setPractices] = useState([])
    const [practice, setPractice] = useState('')
    const [disablePractice, setDisablePractice] = useState(false)
    const {setAuthentication} = useAuthentication()
    const {setUser} = useContext(AuthenticationContext)
    const [medicalAid, setMedicalAid] = useState(false)
    const [medicalAids, setMedicalAids] = useState([])

    function useQuery() {
        const {search} = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }

    let history = useHistory()
    let query = useQuery();

    function login_user(user_credentials) {
        setLoading(true)

        axios.post(API_ROUTES.AUTHENTICATION.LOGIN, user_credentials).then(json => {
            setUser(json.data)
            setAuthentication(json.data)
            history.push(`/`);
            window.location.reload()
            SuccessMsg('Welcome to Clavkoo')
        }).catch(error => {
            ErrorMsg('Credentials not valid, please try again')
            Sentry.captureException(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    function register_patient() {
        setRequested(true)
        axios.post(CMS_URLS.AUTHENTICATION.REGISTER, details).then(res => {
            SuccessMsg('Thank you for requesting access, we will be in touch soon!')
            login_user(res.data)
        }).catch(error => {
            ErrorMsg(error.response.data?.error)
            Sentry.captureException(error);
        }).finally(() => {
            setLoading(false)
            setRequested(false)
        });
    }

    useEffect(() => {
        axios.get(CMS_URLS.PRACTICES.LIST)
            .then(res => setPractices(res.data))
            .catch(err => console.error(err))
            .finally(() => {
                if (query.get('practice') !== null) {
                    setPractice(query.get('practice'))
                    setDisablePractice(true)
                }
            })

        axios.get(CMS_URLS.USERS.MEDICAL_AIDS)
            .then(res => setMedicalAids(res.data))
            .catch(err => console.error(err))
    }, [])

    return <>
        <div className="max-w-4xl mx-auto flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    className="mx-auto h-32 w-auto"
                    src={LOGO}
                    alt="WiEnergi"
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-600">Register as a
                    Patient</h2>
            </div>

            <div className="mt-8 sm:mx-auto max-w-full">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 shadow-2xl border-2 border-gray-200">
                    <form className="space-y-6" method="POST" onSubmit={(event) => {
                        event.preventDefault()
                        register_patient()
                    }}>
                        <div className="grid md:grid-cols-2 gap-2 sm:grid-cols-1">
                            <div>
                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Practice
                                    </label>
                                    <div className="mt-1">
                                        <select
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                            aria-label="form-select-lg example"
                                            onChange={event => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`practice`]: event.target.value,
                                                }))
                                            }}
                                            placeholder={'Patient Practice'}
                                            disabled={requested}
                                        >
                                            <option selected defaultChecked value={'Practice'}>Practice</option>
                                            {
                                                practices.map(p => {
                                                    return <option key={p.id} defaultChecked
                                                                   value={p.id}>{p.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        ID Number
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`id_number`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.id_number}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        First Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`first_name`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.first_name}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Last Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`last_name`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.last_name}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Does patient have medical aid?
                                    </label>
                                    <div className="mt-1">
                                        <Switch
                                            checked={medicalAid}
                                            disabled={requested}
                                            onChange={value => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`medical_aid`]: value,
                                                }))
                                                setMedicalAid(value)
                                            }}
                                            className={`${
                                                medicalAid ? 'bg-emerald-500' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Does patient have medical aid?</span>
                                            <span
                                                className={`${
                                                    medicalAid ? 'translate-x-6' : 'translate-x-1'
                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Referer
                                    </label>
                                    <div className="mt-1">
                                        <select
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                            aria-label="form-select-lg example"
                                            onChange={event => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`referral`]: event.target.value,
                                                }))
                                            }}
                                            placeholder={'Referral'}
                                            disabled={requested}
                                        >
                                            <option selected defaultChecked value={'Title'}>Referral</option>
                                            <option value={'Self'}>Self</option>
                                            <option value={'School'}>School</option>
                                            <option value={'Work'}>Work</option>
                                            <option value={'Clinician'}>Clinician</option>
                                            <option value={'Casualty'}>Casualty</option>
                                            <option value={'In-Hospital'}>In-Hospital</option>
                                            <option value={'NGO'}>NGO</option>
                                            <option value={'Other'}>Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Title
                                    </label>
                                    <div className="mt-1">
                                        <select
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                            aria-label="form-select-lg example"
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`title`]: event.target.value,
                                                }))
                                            }}
                                            placeholder={'Referral'}
                                            disabled={requested}
                                        >
                                            <option selected defaultChecked value={'Title'}>Title</option>
                                            <option value={'Dr'}>Dr</option>
                                            <option value={'Prof'}>Prof</option>
                                            <option value={'Mr'}>Mr</option>
                                            <option value={'Mrs'}>Mrs</option>
                                            <option value={'Ms'}>Ms</option>
                                            <option value={'Miss'}>Miss</option>
                                            <option value={'Other'}>Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Cellphone
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    ['cellphone']: event.target.value,
                                                }))
                                            }}
                                            value={details?.cellphone}
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            required
                                            disabled={requested}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`email`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.email}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            medicalAid ? <div className="grid grid-cols-2 gap-2">

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Medical Aid Name
                                    </label>
                                    <div className="mt-1">
                                        <select
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                            aria-label="form-select-lg example"
                                            onChange={event => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`medical_aid_name`]: event.target.value,
                                                }))
                                            }}
                                            placeholder={'Medical Aid Name'}
                                            disabled={requested}
                                        >
                                            <option selected defaultChecked value={'Medical Aid'}>Medical Aid Name
                                            </option>
                                            {
                                                medicalAids.map(p => {
                                                    return <option key={p.id} defaultChecked
                                                                   value={p.id}>{p.name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Medical Aid Plan
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`medical_aid_plan`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.medical_aid_plan}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Medical Aid Number
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`medical_aid_number`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.medical_aid_number}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Principal Member ID
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`principal_member_id`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.principal_member_id}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Principal Member First Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`principal_name`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.principal_name}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Principal Member Last Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            name="text"
                                            type="text"
                                            disabled={requested}
                                            required
                                            onChange={(event) => {
                                                setDetails((prevState) => ({
                                                    ...prevState,
                                                    [`principal_surname`]: event.target.value,
                                                }))
                                            }}
                                            value={details?.principal_surname}
                                            className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                        />
                                    </div>
                                </div>


                            </div> : null
                        }

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md border border-transparent bg-emerald-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                {
                                    loading ? <CogIcon
                                            className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                        <>Register as Patient</>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
}
