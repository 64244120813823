import {Link} from "react-router-dom";
import {useUtils} from "../../hooks/utils-hook";
import React from "react";

export function FormCard({form, exists = false}) {

    let {ConvertDateToString} = useUtils()
    return <div className='mr-2 ml-2'>
        <Link
            className="group flex flex-col bg-white border-2 shadow-sm rounded hover:shadow-md transition dark:bg-slate-100 border-gray-300 h-28 hover:bg-gray-200"
            to={`/forms/${form?.record_form?.id}`}>
            <div className="p-4 md:p-5">
                <div className="flex">
                    {
                        exists ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                      strokeWidth={1.5} stroke="currentColor"
                                      className="mt-1 shrink-0 w-10 h-10 text-green-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"/>
                        </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth={1.5} stroke="currentColor"
                                 className="mt-1 shrink-0 w-10 h-10 text-orange-600">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                            </svg>
                    }

                    <div className="grow ml-5">
                        <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-900 dark:text-gray-900">
                            {form?.record_form?.name}
                        </h3>
                        <p className="text-sm text-gray-900">
                            Completed {ConvertDateToString(form?.created)}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
}
