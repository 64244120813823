
export default function Loader({full_height = false}) {
    return <>
        <div
            className={`self-auto text-center place-content-center mt-5 pt-5 mb-5 pb-5 rowspan-7 bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/60 sm:to-white/25 ${full_height ? "h-screen" : ""}`}>
            <section
                className={`hero container max-w-screen-lg mx-auto pb-10 text-blue-500 justify-center text-center items-center`}>
                <p className='mt-48'>
                    <svg className='animate-spin h-10 w-10 text-emerald-500 text-center items-center m-auto' aria-hidden="true" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                </p>
            </section>
        </div>
    </>
}
