import {useContext, useEffect, useState} from "react";
import {CrumbsContext} from "../contexts/crumbs-context";
import Breadcrumbs from "../components/breadcrumbs";
import {useAuthentication} from "../hooks/AuthenticationHook";

export default function Profile() {
    let {currentUser} = useAuthentication()
    let {setCrumbs} = useContext(CrumbsContext)
    const [details, setDetails] = useState(currentUser())


    useEffect(() => {
        setCrumbs([{'name': 'Profile', 'link': '/profile'},
            {'name': `${currentUser().first_name} ${currentUser().last_name}`, 'link': '/profile'}])
    }, [])

    return <>
        <Breadcrumbs/>
        <div className="max-w-7xl px-6 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="bg-white rounded shadow-2xl p-4 sm:p-16 border-2 border-gray-300">
                <form>
                    <div
                        className="grid sm:grid-cols-12 gap-2 sm:gap-4 py-8 first:pt-0 last:pb-0 border-t first:border-transparent">
                        <div className="sm:col-span-12">
                            <h2 className="text-lg font-semibold text-gray-600">
                                Personal Details
                            </h2>
                        </div>
                        <div className="sm:col-span-4">
                            <label for="af-submit-application-full-name" aria-autocomplete="none"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Full name
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <div className="sm:flex">
                                <input id="af-submit-application-full-name" type="text" aria-autocomplete="none"
                                       className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-l-lg focus:border-green-500 focus:ring-green-500"
                                       value={details?.first_name}
                                />
                                <input type="text" aria-autocomplete="none"
                                       className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-r-lg focus:border-green-500 focus:ring-green-500"
                                       value={details?.last_name}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-email"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                ID Number
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-email" type="email" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.id_number}
                            />
                        </div>


                        <div className="sm:col-span-4">
                            <label for="af-submit-application-email"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Email
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-email" type="email" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.email}
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <div className="inline-block">
                                <label for="af-submit-application-phone"
                                       className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                    Phone
                                </label>
                            </div>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-phone" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.cellphone}
                            />
                        </div>
                    </div>

                    <div
                        className="grid sm:grid-cols-12 gap-2 sm:gap-4 py-8 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-300">
                        <div className="sm:col-span-12">
                            <h2 className="text-lg font-semibold text-gray-600">
                                Medical Aid Details
                            </h2>
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-linkedin-url"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Medical Aid Name
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-linkedin-url" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.medical_aid?.provider?.name} disabled/>
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-twitter-url"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Medical Aid Plan
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-linkedin-url" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                            value={details?.medical_aid?.plan} disabled
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-github-url"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Medical Aid Number
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-linkedin-url" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.medical_aid?.number} disabled
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-portfolio-url"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Principal Member ID
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-linkedin-url" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.medical_aid?.principal_member_id} disabled
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-other-website"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Principal Member First Name
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-linkedin-url" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.medical_aid?.principal_name} disabled
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <label for="af-submit-application-other-website"
                                   className="inline-block text-sm font-medium text-gray-500 mt-2.5">
                                Principal Member Last Name
                            </label>
                        </div>

                        <div className="sm:col-span-8">
                            <input id="af-submit-application-linkedin-url" type="text" aria-autocomplete="none"
                                   className="py-2 px-3 pr-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-green-500 focus:ring-green-500"
                                   value={details?.medical_aid?.principal_surname} disabled
                            />
                        </div>
                    </div>

                    <div
                        className="py-8 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-300">
                    </div>

                    {/*<button type="button"*/}
                    {/*        className="py-3 px-4 w-full inline-flex justify-center items-center gap-2 rounded border border-transparent font-semibold bg-green-600 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-green-600">*/}
                    {/*    Save Changes*/}
                    {/*</button>*/}
                </form>
            </div>
        </div>
    </>
}
