import html2pdf from 'html2pdf.js';
function RemoveEmptyProps(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}
function BuildFormData(details) {
    let data = new FormData()
    let cleanDetails = RemoveEmptyProps(details)
    let detailsKeyArray = Object.keys(cleanDetails)
    detailsKeyArray.map(key => {
        if (cleanDetails[key])
            data.append(key, cleanDetails[key]);
    })
    return data
}

function ConvertDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}`
}

function DownloadPdf(elementId, fileName) {
    const element = document.getElementById(elementId);
    const options = {
        margin: [10, 5, 0, 0], // top, right, bottom, left margins
        filename: `${fileName}.pdf`,
        image: {type: 'jpeg', quality: 1},
        html2canvas: {dpi: 2000, letterRendering: true, disableFontFace: true},
        jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'},
        pagebreak: {mode: 'avoid-all'},
        beforePageContent: function (data) {
            if (data.pageNumber > 1) {
                return '<div style="height: 80mm"></div>';
            }
            const imgData = document.getElementById('logo').toDataURL('image/jpeg', 1);
            return '<img src="' + imgData + '" class="text-center align-self-center m-auto"  style="height: 100px"/>';
        },
        afterPageContent: function (data) {
            return '<div style="text-align:center;">Page ' + data.pageNumber + ' of ' + data.settings.totalPages + '</div>';
        },
    };
    html2pdf().set(options).from(element).save();
}

export const useUtils = () => {
    return {BuildFormData, ConvertDateToString, DownloadPdf}
}
