import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_ROUTES} from "../../routes/api-routes";
import {useUtils} from "../../hooks/utils-hook";
import {Link} from "react-router-dom";
import {FormCard} from "../cards/form";

export function CompletedForms({id_number}) {
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(true)



    useEffect(() => {
        console.log(API_ROUTES.FORMS.RECORDS(id_number))
        axios.get(API_ROUTES.FORMS.RECORDS(id_number))
            .then(res => {
                setRecords(res.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }, [])

    return <>
        <div className="max-w-7xl mx-auto mt-5 mb-8">
            <h1 className="text-xl font-bold text-gray-700 mb-5">Completed forms</h1>
            <div className="grid grid-cols-4 gap-2">
                {
                    records.map((form, i) => {
                        return <FormCard key={i} form={form} exists={true}/>
                    })
                }
            </div>
        </div>
    </>
}
