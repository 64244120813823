import React, {useState, Fragment} from "react";
import {Switch} from '@headlessui/react'

export function Question({question_data, list, setMethod, parent}) {
    let [colour, setColour] = useState('#27ae60')
    const [enabled, setEnabled] = useState(false)
    console.log('Question:', question_data)

    function change_colour(value) {
        if (value <= 33)
            setColour('#27ae60')
        if (value > 33 && value <= 66)
            setColour('#2980b9')
        if (value > 66)
            setColour('#f1c40f')
    }

    function get_parent(sections, parent_id) {
        return sections.find(function (sec) {
            return sec.id === parent_id
        })
    }

    function change_answer(answer) {
        const section = get_parent(list, parent)
        const section_index = list.indexOf(section)

        let question = get_parent(section.questions, question_data.id)
        const question_index = section.questions.indexOf(question)

        question.answer = answer

        section.questions[question_index] = question

        list[section_index] = section
        setMethod(list)
    }

    function change_slider_answer(answer, options) {
        const word_answer = options[answer];
        change_answer(word_answer)
    }

    return (
        <div className="col-md-6 col-sm-12">
            <div className="form-group">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-8">
                        <label htmlFor="firstName1 text-upper text-up mb--3 text-bold">
                            {question_data.question}:
                        </label>
                    </div>
                    <div className="col-span-4">
                        {
                            question_data.type === "dropdown" ?
                                <div className="col-md-12">
                                    <select id={question_data.id}
                                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                            style={{width: '100%'}}
                                            defaultValue="Choose..."
                                            onChange={value => {
                                                change_answer(value)
                                            }}>
                                        {
                                            question_data.options.map((option, x) => {
                                                return <option key={x} value={option}>{option}</option>
                                            })}
                                    </select>

                                </div>
                                : null
                        }
                        {
                            question_data.type === "text" ?
                                <div className="col-md-6 col-sm-12">
                                    <input className="block w-full appearance-none rounded-md border-2 border-gray-200 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm disabled:bg-gray-200"
                                           id={question_data.id}
                                           placeholder={question_data.question}
                                           onChange={event => {
                                               change_answer(event.target.value)
                                           }}
                                           style={{width: "100%"}}
                                    />
                                </div>
                                : null
                        }
                        {
                            question_data.type === "switch" ?
                                <Switch checked={enabled} onChange={setEnabled} as={Fragment}>
                                    {({checked}) => (
                                        /* Use the `checked` state to conditionally style the button. */
                                        <button
                                            className={`${
                                                checked ? 'bg-green-600' : 'bg-gray-200'
                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">Enable notifications</span>
                                            <span
                                                className={`${
                                                    checked ? 'translate-x-6' : 'translate-x-1'
                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                            />
                                        </button>
                                    )}
                                </Switch>
                                : null
                        }
                        {/*{*/}
                        {/*    question_data.type === "slider" ?*/}
                        {/*        <div className="col-md-12 col-sm-12">*/}
                        {/*            <Slider marks={question_data.marks}*/}
                        {/*                    className='col-12'*/}
                        {/*                    step={null}*/}
                        {/*                    trackStyle={{backgroundColor: colour}}*/}
                        {/*                    handleStyle={{borderColor: colour}}*/}
                        {/*                    defaultValue={0}*/}
                        {/*                    max={100}*/}
                        {/*                    min={0}*/}
                        {/*                    onChange={event => {*/}
                        {/*                        change_colour(event)*/}
                        {/*                        change_slider_answer(event, question_data.marks)*/}
                        {/*                    }}/>*/}
                        {/*        </div>*/}
                        {/*        : null*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
