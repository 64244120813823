import React, {useContext} from 'react'

import Loader from "../components/loader";
import {LoaderContext} from "../contexts/loader-context";
import Header from "../components/header";
import Footer from "../components/footer";

export default function PageLayout({component = null}) {
    let {loading} = useContext(LoaderContext)
    return <>
        <div>
            <Header/>
            {
                loading ? <Loader full_height={true}/> : <>{(component)}</>
            }
            <Footer/>
        </div>
    </>
}
