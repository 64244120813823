import {Question} from "../record/question";
import {useState} from "react";
import FormNavigation from "../cards/form-navigation";

export function QuestionForm({form}) {
    const [list, setMethod] = useState({})
    const [step, setStep] = useState(1)

    return <div className='shadow-2xl w-full border-2 border-r-2 mb-24 p-3 rounded'>
        <div className="bg-white py-10">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-2xl font-bold tracking-tight text-gray-700 sm:text-2xl">{form?.name}</h2>
            </div>
            <FormNavigation sections={form?.sections} setStep={setStep} step={step}/>
        </div>

        <section>
            <div className="row">
                {
                    form?.sections !== undefined ?
                        <div className="row col-12 text-uppercase mt-0">
                            <h4 className='mb-0 text-center'><strong>{form?.sections[step - 1]?.name}</strong></h4>

                        </div> : null
                }

                <div className='p-6'>
                    {form?.sections !== undefined ?
                        form?.sections[step - 1]?.questions?.map((section, i) => {
                            return <>
                                <div className="row col-12 text-uppercase mt-4">
                                    <h4 className='mb-4'><strong>{section?.name}</strong></h4>
                                    <hr className="col-12"/>
                                </div>
                                <div className='grid grid-cols-2 gap-4 mt-2'>
                                    {
                                        section?.questions?.map((question, key) => {
                                            return (
                                                <Question key={key} question_data={question} list={list}
                                                          setMethod={setMethod}
                                                          parent={section.id}/>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }) : null
                    }
                </div>

            </div>
        </section>
        <hr className="col-12 mt-4"/>
        <div className="mt-6 flex items-center justify-end gap-x-6">
            <button type="button"  onClick={() => {
                if (step > 1)
                    setStep(step - 1)
            }} className="rounded-md bg-gray-400 px-14 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                Back
            </button>
            <button
                onClick={() => {
                    setStep(step + 1)
                }}
                className="rounded-md bg-green-600 px-14 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
                {
                    step === form?.sections?.length+1 ? 'Save' : 'Next'
                }
            </button>
        </div>
    </div>
}
