import {useEffect, useState} from "react";

export default function FormNavigation({step, setStep, sections=[]}) {
    const [progress, setProgress] = useState(0)

    function calculateProgressWidth() {
        if(step === 1)
            return setProgress(0)
        const progressWidth = ((step-1) / (sections.length)) * 100;
        console.log(progressWidth)
        console.log(step)
        console.log(sections.length)
        return setProgress(progressWidth)
    }

    useEffect(() => {
        calculateProgressWidth()
    }, [step])
    return <>
        <div>
            <h4 className="sr-only">Status</h4>
            <div className="mt-6 bg-gray-100 p-6 rounded border border-2 border-gray-200" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                    <div className="h-2 rounded-full bg-green-600" style={{width: `${progress}%`}}/>
                </div>
                <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                    {
                        sections?.map((section, i) => {
                            return <div key={i} className={`cursor-pointer text-emerald-600 ${i !== 0 ? 'text-center' : ''} ${i+1===step ? 'font-bold' : ''}`}>{section?.name}
                            </div>
                        })
                    }
                    <div className={`text-right text-emerald-600 ${sections.length===step ? 'font-bold' : ''}`}>Confirmation</div>
                </div>
            </div>
        </div>
    </>
}
