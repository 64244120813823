import {PORTAL_MENU} from "../../routes/portal";
import {Link} from "react-router-dom";
import LOGO from 'assets/Icon.svg'
export default function Footer() {
    return <>
        <footer aria-label="Site Footer" className="bg-gray-100">
            <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
                <div className="flex justify-center text-teal-600">
                    <img className="h-8" src={LOGO} alt={'Clavkoo'} />
                </div>

                <p className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500">
                    Clavkoo is an Internet-based online information and communication service.
                </p>

                <nav aria-label="Footer Nav" className="mt-12">
                    <ul className="flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12">
                        {
                            PORTAL_MENU.filter(x=>x.menu).map(menu => {
                                return <li key={menu.name}>
                                    <Link to={menu.link} className="text-gray-700 transition hover:text-gray-700/75">
                                        {menu.name}
                                    </Link>
                                </li>
                            })
                        }
                    </ul>
                </nav>
            </div>
        </footer>
    </>
}
