import Home from "../pages/home";
import Forms from "../pages/forms";
import Login from "../pages/login";
import FormDetail from "../pages/form-detail";
import Register from "../pages/register";
import Profile from "../pages/profile";

export const PORTAL_MENU = [
    {
        name: 'Home',
        link: '/',
        private: true,
        exact: true,
        menu: true,
        page: Home
    },
    {
        name: 'My Forms',
        link: '/forms',
        private: true,
        exact: true,
        menu: true,
        page: Forms
    },
    {
        name: 'Form Detail',
        link: '/forms/:id',
        private: true,
        exact: true,
        menu: false,
        page: FormDetail
    },
    {
        name: 'My Profile',
        link: '/profile',
        private: true,
        exact: true,
        menu: true,
        page: Profile
    },
    {
        name: 'Login',
        link: '/login',
        private: false,
        exact: true,
        menu: false,
        page: Login
    },
    {
        name: 'Register',
        link: '/register',
        private: false,
        exact: true,
        menu: false,
        page: Register
    },
]
