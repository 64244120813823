import React, {useEffect, useState} from "react";
import axios from "axios";
import {useAuthentication} from "../../hooks/AuthenticationHook";
import {API_ROUTES} from "../../routes/api-routes";
import {Link} from "react-router-dom";

export function FormsGrid({id_number}) {
    const [forms, setForms] = useState([])
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(true)


    function form_exists(form_id) {
        if (!records.length > 0) {
            return false
        }
        return records.find(obj => {
            return obj.record_form.id === form_id
        }) !== undefined;
    }


    useEffect(() => {
        axios.get(API_ROUTES.FORMS.TYPES)
            .then(res => {
                setForms(res.data)
            })
            .catch(err => {
                console.error(err)
            })

        axios.get(API_ROUTES.FORMS.RECORDS(id_number))
            .then(res => {
                setRecords(res.data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => setLoading(false))
    }, [])

    return <>
        <div className="max-w-7xl mx-auto mt-8 mb-10">
            <h1 className="text-xl font-bold text-gray-700 mb-5">Outstanding forms</h1>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
                {
                    forms.filter(x => x.visible_to_patients).map(form => {
                        return !form_exists(form.id) ? <Link
                            className="group flex flex-col bg-white border-2 shadow-sm rounded hover:shadow-md transition dark:bg-slate-100 border-gray-300 h-28 hover:bg-gray-200"
                            to={`/forms/${form?.id}`}>
                            <div className="p-4 md:p-5">
                                <div className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor"
                                         className="mt-1 shrink-0 w-10 h-10 text-orange-600">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
                                    </svg>
                                    <div className="grow ml-5">
                                        <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-900 dark:text-gray-900">
                                            {form?.name}
                                        </h3>
                                        <p className="text-sm text-gray-500">
                                            Click to complete
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Link> : <React.Fragment key={form.id}></React.Fragment>
                    })
                }
                {forms.filter(x => x.visible_to_patients).length === 0 ? <p>No forms</p> : <></>}
            </div>
        </div>
    </>
}
