import {useContext, useEffect} from "react";
import {LoaderContext} from "../contexts/loader-context";
import {FormsGrid} from "../components/forms/forms-grid";
import {useAuthentication} from "../hooks/AuthenticationHook";
import {CompletedForms} from "../components/forms/completed";
import {PersonCard} from "../components/cards/person-card";
import Breadcrumbs from "../components/breadcrumbs";
import {CrumbsContext} from "../contexts/crumbs-context";

export default function Home() {
    let {setLoading} = useContext(LoaderContext)
    let {currentUser} = useAuthentication()
    let {setCrumbs} = useContext(CrumbsContext)

    useEffect(() => {
        setCrumbs([{'name': 'Dashboard', 'link': '/'}])
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [])

    return <>
        <Breadcrumbs/>
        <PersonCard user={currentUser()}/>
        <FormsGrid id_number={currentUser()?.id_number}/>
        <CompletedForms id_number={currentUser()?.id_number}/>
    </>
}
