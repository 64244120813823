import {useAuthentication} from "../hooks/AuthenticationHook";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {API_ROUTES} from "../routes/api-routes";
import {useClavkooForms} from "../hooks/form-hooks";
import Breadcrumbs from "../components/breadcrumbs";
import {CrumbsContext} from "../contexts/crumbs-context";
import {QuestionResponse} from "../components/forms/question-response";
import {QuestionForm} from "../components/forms/question-form";

export default function FormDetail() {
    const [exists, setExists] = useState(false)
    const [record, setRecord] = useState({})
    const [form, setForm] = useState({})
    let {id} = useParams();
    let {currentUser} = useAuthentication()
    let {recordExists} = useClavkooForms()
    let {setCrumbs} = useContext(CrumbsContext)

    function LoadRecords() {
        axios.get(API_ROUTES.FORMS.RECORDS_DETAIL(currentUser()?.id_number, id))
            .then(res => {
                setExists(recordExists(res.data))
                setRecord(res.data[0])
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => {
            })
    }

    useEffect(() => {
        setCrumbs([{'name': 'Forms', 'link': '/forms'}, {
            'name': record?.record_form?.name ?? form?.name,
            'link': `/forms/${record?.record_form?.id ?? form?.id}`
        }, {'name': `${currentUser()?.first_name} ${currentUser()?.last_name}`, 'link': '#'}])
    }, [record, form])

    function LoadForm() {
        console.log(API_ROUTES.FORMS.DETAIL(id))
        axios.get(API_ROUTES.FORMS.DETAIL(id))
            .then(res => {
                setForm(res.data)
            })
            .catch(err => Sentry.captureException(err))
            .finally(() => {
            })
    }

    useEffect(() => {
        LoadForm()
        LoadRecords()
    }, [id])

    return <>
        <Breadcrumbs/>
        <div className="max-w-7xl mx-auto mt-5">
            { exists ? <QuestionResponse record={record} /> : <QuestionForm form={form ?? {}} /> }
        </div>
    </>
}
