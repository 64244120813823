function find_object_in_list(list = [], id) {
    return list.find(obj => {
        return obj.id === id
    })
}

function find_record_by_type(list = [], id) {
    return list.find(obj => {
        return obj.record_form.id === id
    })
}

function switchOpposite(value) {
    if (value === "Yes")
        return "No"
    return "Yes"
}


export const useClavkooForms = () => {
    function getSwitchValue(id, list) {
        if (Array.isArray(list)) {
            return find_object_in_list(list.questions, id)
        }
        return find_object_in_list(list)
    }

    function changeSwitchValue(id, list, setMethod, parent) {
        let question_list = find_object_in_list(list, parent).questions

        let question = find_object_in_list(question_list, id)

        let question_list_index = list.indexOf(find_object_in_list(list, parent))
        let question_index = question_list.indexOf(question);

        question.answer = switchOpposite(question.answer)

        question_list[question_index] = question

        list[question_list_index].questions = question_list
        setMethod(list)
    }

    function changeDropdownValue(id, value, list, setMethod, parent) {
        const question_list = find_object_in_list(list, parent).questions
        const question = find_object_in_list(question_list, id)

        const question_list_index = list.indexOf(find_object_in_list(list, parent))
        const question_index = question_list.indexOf(question);

        question.answer = value

        question_list[question_index] = question

        list[question_list_index].questions = question_list
        setMethod(list)
    }

    function changeTextValue(id, value, list, setMethod, parent) {
        changeDropdownValue(id, value, list, setMethod, parent)
    }

    function recordExists(obj) {
        return !!obj.length > 0;
    }

    function setSliderValue(id, list, options, value, setMethod, parent) {
        changeDropdownValue(id, find_object_in_list(options, value).text, list, setMethod, parent)
    }

    function getGenderDescription(gender) {
        if (gender === 'F')
            return 'Female'
        return 'Male'
    }

    return {
        getSwitchValue,
        changeSwitchValue,
        changeDropdownValue,
        recordExists,
        changeTextValue,
        setSliderValue,
        getGenderDescription,
        find_record_by_type
    }
}