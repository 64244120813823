const CMS_VERSION = "v1"
const CMS_BASE_URL = "https://api.clavkoo.org/api"
const CMS_URL = `${CMS_BASE_URL}/${CMS_VERSION}`
export const API_ROUTES = {
    AUTHENTICATION: {
        LOGIN: `${CMS_URL}/users/obtain_token`,
        USER_OTP: `${CMS_URL}/users/otp`,
        REGISTER: `${CMS_URL}/users/patient/register`,
    },
    USERS: {
        MEDICAL_AIDS: `${CMS_URL}/users/medical-aids`
    },
    PRACTICES: {
        LIST: `${CMS_URL}/practices/all`,
        CLINICIANS: `${CMS_BASE_URL}/users/clinicians`
    },
    DOCUMENTS: {
        PATIENT: function (patient_id) {
            return `${CMS_BASE_URL}/documents/patient/${patient_id}`

        },
        DOCUMENT: `${CMS_BASE_URL}/documents`
    },
    FORMS: {
        DETAIL: function (form_id) {
            return `${CMS_URL}/forms/${form_id}`
        },
        RECORDS: function (patient_id, form_type_id) {
            return `${CMS_URL}/forms/records/${patient_id}`
        },
        RECORDS_DETAIL: function (patient_id, form_type_id) {
            return `${CMS_URL}/forms/records/${patient_id}/${form_type_id}`
        },
        TYPES: `${CMS_URL}/forms`,
        COMMUNICATION: `${CMS_URL}/forms/send`
    }
}
